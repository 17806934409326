<template>
  <div class="bg-cwe">
      <div class="container-my" v-loading="loadVacancy">
          <div class="logins text-center">
              <img class="bekmega" src="/img/gohr_new.png" alt="" />
              <img class="gohr" src="/img/logo_dark.png" alt="" />
          </div>
          <div class="block_1">
              <a href="#aaaaaa" class="link-mobil">
                  <i class="el-icon-edit-outline"></i>
                  {{ $t("message.complete_form") }}
                  <i class="el-icon-arrow-down"></i>
              </a>

              <el-row :gutter="20">
                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2"
                              >{{ $t("message.name") }}:
                          </strong>
                          <span v-if="columns.name.show">
                              {{ vacancy_for_candidate.name }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.gender") }}:
                          </strong>
                          <span
                              v-for="(
                                  gender, genderIndex
                              ) in vacancy_for_candidate.genders"
                              :key="'gender' + genderIndex"
                          >
                              {{ gender.name + " " }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.age") }}:
                          </strong>
                          <span
                              v-if="
                                  columns.min_age.show && columns.max_age.show
                              "
                          >
                              {{
                                  vacancy_for_candidate.min_age +
                                  "-" +
                                  vacancy_for_candidate.max_age
                              }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.branch") }}:
                          </strong>
                          <span v-if="columns.branch_id.show">
                              {{
                                  vacancy_for_candidate.branch_id
                                      ? vacancy_for_candidate.branch.name
                                      : null
                              }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.department") }}:
                          </strong>
                          <span
                              v-for="(
                                  department, departmentIndex
                              ) in vacancy_for_candidate.departments"
                              :key="'department' + departmentIndex"
                          >
                              {{ department.name }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.priority") }}:
                          </strong>
                          <span v-if="columns.priority_id.show">
                              {{
                                  vacancy_for_candidate.priority_id
                                      ? vacancy_for_candidate.priority.name
                                      : null
                              }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.position") }}:
                          </strong>
                          <span v-if="columns.position_id.show">
                              {{
                                  vacancy_for_candidate.position_id
                                      ? vacancy_for_candidate.position.name
                                      : null
                              }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.employmentType") }}:
                          </strong>
                          <span v-if="columns.employment_type_id.show">
                              {{
                                  vacancy_for_candidate.employment_type_id
                                      ? vacancy_for_candidate.employment_type
                                            .type
                                      : null
                              }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.language") }}:
                          </strong>
                          <span
                              v-for="(
                                  language, languageIndex
                              ) in vacancy_for_candidate.languages"
                              :key="'language' + languageIndex"
                          >
                              {{ language.name }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.wage") }}:
                          </strong>
                          <span
                              v-if="
                                  columns.min_wage.show &&
                                  columns.max_wage.show
                              "
                          >
                              {{
                                  vacancy_for_candidate.min_wage +
                                  "-" +
                                  vacancy_for_candidate.max_wage
                              }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.skill") }}:
                          </strong>
                          <span
                              v-for="(
                                  skill, skillIndex
                              ) in vacancy_for_candidate.skills"
                              :key="'skill' + skillIndex"
                          >
                              {{ skill.skill }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.district") }}:
                          </strong>
                          <span v-if="columns.district_id.show">
                              {{
                                  vacancy_for_candidate.district_id
                                      ? vacancy_for_candidate.district.name
                                      : null
                              }}
                          </span>
                      </p>
                  </el-col>

                  <el-col :span="24">
                      <p class="font-medium pb-1 pt-0 mb-3 mt-3 text-left">
                          <strong class="mr-1 font-bold mr-2">
                              {{ $t("message.description") }}:
                          </strong>
                          <span>
                              {{ vacancy_for_candidate.description }}
                          </span>
                      </p>
                  </el-col>
              </el-row>
          </div>

          <div
              id="aaaaaa"
              class="block_2"
              v-if="vacancy_for_candidate.status.code != 0&&vacancy_for_candidate.limit>0"
          >
            <el-form ref="form" status-icon :model="form" :rules="rules">
              <el-divider content-position="left">
                  {{ $t("message.complete_form") }}
              </el-divider>

                  <el-row :gutter="20">
                      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.nameFull") }}
                              </span>
                              <el-form-item prop="name">
                                  <el-input
                                      :placeholder="
                                          candidateColumns.name.title
                                      "
                                      v-model="form.name"
                                      size="medium"
                                  ></el-input>
                              </el-form-item>
                          </div>

                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.middle_name") }}
                              </span>
                              <el-input
                                  :placeholder="
                                      candidateColumns.middle_name.title
                                  "
                                  v-model="form.middle_name"
                                  size="medium"
                              ></el-input>
                          </div>

                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.last_name") }}
                              </span>
                              <el-form-item prop="last_name" size="mini">
                                  <el-input
                                      :placeholder="
                                          candidateColumns.last_name.title
                                      "
                                      v-model="form.last_name"
                                      size="medium"
                                  >
                                  </el-input>
                              </el-form-item>
                          </div>

                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.date_of_birth") }}
                              </span>
                              <crm-date-picker
                                  :date="form.date_of_birth"
                                  :placeholder="
                                      candidateColumns.date_of_birth.title
                                  "
                                  v-model="form.date_of_birth"
                                  :size="'medium'"
                              ></crm-date-picker>
                          </div>

                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.nation") }}
                              </span>
                              <el-form-item prop="nation_id" size="mini">
                                  <el-select
                                      :placeholder="$t('message.nation')"
                                      filterable
                                      clearable
                                      :size="'medium'"
                                      class="d-block"
                                      v-model="form.nation_id"
                                  >
                                      <el-option
                                          v-for="(nation, index) in nations"
                                          :key="'nations-' + index"
                                          :label="nation.nationality"
                                          :value="nation.id"
                                      >
                                      </el-option>
                                  </el-select>
                              </el-form-item>
                          </div>
                      </el-col>
                      <!-- end col -->

                      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.phone_number_y") }}
                              </span>
                              <crm-input
                                  :placeholder="
                                      candidateColumns.phone_number.title
                                  "
                                  :input-value="form.phone_number"
                                  v-model="form.phone_number"
                                  type="tel"
                                  autocomplete="off"
                                  size="medium"
                                  :maskFormat="'tel'"
                              ></crm-input>
                          </div>

                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.min_wage_y") }}
                              </span>
                              <el-input
                                  :placeholder="
                                      candidateColumns.min_wage.title
                                  "
                                  v-model="form.min_wage"
                                  type="number"
                                  size="medium"
                              >
                              </el-input>
                          </div>

                          <!-- <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.max_wage_y") }}
                              </span>
                              <el-input
                                  :placeholder="
                                      candidateColumns.max_wage.title
                                  "
                                  v-model="form.max_wage"
                                  size="medium"
                                  type="number"
                              ></el-input>
                          </div> -->

                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.email") }}
                              </span>
                              <el-input
                                  :placeholder="candidateColumns.email.title"
                                  v-model="form.email"
                                  type="email"
                                  size="medium"
                              ></el-input>
                          </div>

                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.gender") }}
                              </span>
                              <el-form-item prop="gender_id">
                                  <el-select
                                      :placeholder="$t('message.gender')"
                                      filterable
                                      clearable
                                      :size="'medium'"
                                      class="d-block"
                                      v-model="form.gender_id"
                                  >
                                      <el-option
                                          v-for="(gender, index) in genders"
                                          :key="'genders-' + index"
                                          :label="gender.name"
                                          :value="gender.id"
                                      >
                                      </el-option>
                                  </el-select>
                              </el-form-item>
                          </div>

                          <div class="app-form__group mb-4">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.summary") }}
                              </span>
                              <el-upload
                                  :on-preview="handleFilePreview"
                                  :on-remove="handleFileRemove"
                                  :before-remove="beforeFileRemove"
                                  action="/"
                                  :limit="1"
                                  :on-change="createFileList"
                                  :file-list="fileList"
                                  class="upload-demo"
                                  list-type="file"
                                  :auto-upload="false"
                              >
                                  <el-button size="small" type="primary">
                                      {{ $t("message.UploadFile") }}
                                  </el-button>
                                  <div slot="tip" class="el-upload__tip">
                                      {{ $t("message.UploadFileText") }}
                                  </div>
                              </el-upload>
                          </div>
                      </el-col>
                      <!-- end col -->

                      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                          <div class="app-form__group mb-4 my-upload-2">
                              <span class="input--label d-block mb-2">
                                  {{ $t("message.photo") }}
                              </span>
                              <el-upload
                                  :on-preview="handlePictureCardPreview"
                                  :on-remove="handleImageRemove"
                                  class="upload-demo"
                                  action="/"
                                  :limit="1"
                                  :on-change="createImageList"
                                  :file-list="imageList"
                                  list-type="picture-card"
                                  :auto-upload="false"
                              >
                                  <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-dialog :visible.sync="dialogVisible">
                                  <img
                                      width="100%"
                                      :src="dialogImageUrl"
                                      alt=""
                                  />
                              </el-dialog>
                          </div>
                      </el-col>
                      <!-- end col -->

                      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                      </el-col>
                  </el-row>
                  <div v-if="vacancy_for_candidate.check_level">
                    <el-divider content-position="left">
                        {{ $t("Iltimos Quydagi savollarga javob bering!") }}
                    </el-divider>
                    <div>
                      <span class="input--label d-block mb-2">
                        {{ $t("Test topshirish tili") }}
                      </span>
                      <el-form-item prop="lang_key">
                        <el-select  class="d-block" size="small"  v-model="lang_key"  :placeholder="$t('Test topshirish tili')"
                        :change="changLang" >
                          <el-option
                            :label="$t('Uzbek')"
                            :value="'uz'"
                          >
                          </el-option>
                          <el-option
                            :label="$t('Russia')"
                            :value="'ru'"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                      <div class="quiz" v-if="lang_key">
                        <div class="quiz-item" v-for="(question, index) in questions" :key="'questions-'+index">
                          <div class="question">
                            <h4 class="questiuon">Question №{{ (index+1) }}: {{question.content}}</h4>
                            <div class="demo-image__lazy" v-if="question.media_type=='photo'">
                             <el-image 
                               style="width: 500px; height: 400px"
                               :src="backendUrl+question.media_url" 
                               :preview-src-list="[backendUrl+question.media_url]">
                             </el-image>
                           </div>
                          </div>
                          <div class="question_options">
                            <div v-for="option in question.options"  :key="'option-'+option.id" class="option_item" >
                              <el-radio v-model="user_options[index]"   :label="option.id">
                                <el-image 
                                  style="width: 100px; height: 100px"
                                  :src="backendUrl+option.media_url" 
                                  :preview-src-list="[backendUrl+option.media_url]"
                                  v-if="option.media_type=='photo'">
                                </el-image>
                               <span v-else> {{option.title}}</span>
                              </el-radio>
                            </div>
                          </div>
                          <!-- <pre>
                            {{user_options}}
                          </pre> -->
                        </div>
                      </div>
                    <!-- {{ questions }} -->
                  </div>
                  <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="text-center m-3">
                            <el-button
                                type="success"
                                class="pl-5 pr-5"
                                round
                                @click="submit(true)"
                            >
                                Отправить
                            </el-button>
                        </div>
                    </el-col>
                  </el-row>
            </el-form>

          </div>
          <div class="block_2" v-else>
              {{ $t("message.vacancyNotAvailable") }}
          </div>
      </div>
  </div>
</template>
<script>
import { mapGetters, mapActions  } from "vuex";
import axios from "axios";
export default {
  name: "applicationCandidates",
  components: {
      //
  },
  watch:{
    lang_key:{
        handler: function (newVal, oldVal) {
          if (newVal != oldVal) {
            console.log(newVal);
            this.fetchQuestions()
          }
        },
        deep: true,
        immediate: true,
      }
    },
  data() {
      return {
          test:'',
         lang_key:'uz',
          vacancyUuid: this.$route.params.vacancyUuid,
          baseUrl: process.env.VUE_APP_URL,

          backendUrl: process.env.VUE_APP_URL.substring(
            0,
            process.env.VUE_APP_URL.length - 3
          ),
          vacancy_ids: [],
          form: {},
          fileList: [],
          imageList: [],
          dialogImageUrl: "",
          dialogVisible: false,
          vacancy_for_candidate: {},
          genders: [],
          nations: [],
          statuses: [],
          questions: [],
          user_options:{},
          loadVacancy: false,
          max_wage: 0,
      };
  },
  created() {
      this.fetchStatuses();
      this.fetchQuestions();
      this.fetchNations();
      this.fetchGenders();
      this.fetchVacancyForCandidate();
  },
  computed: {
      ...mapGetters({
          candidateColumns: "candidate/columns",
          columns: "vacancy/columns",
          rules: "candidate/rules",
      }),
  },

  methods: {
    ...mapActions({
        save: "candidate/storeAplicationCandidates",
        empty: "candidate/empty",
      }),
      fetchVacancyForCandidate() {
          if (!this.loadVacancy) {
              this.loadVacancy = true;
              axios
                  .get(this.baseUrl + "/vacancy/" + this.vacancyUuid)
                  .then((res) => {
                      this.vacancy_for_candidate =
                          res.data.result.data.vacancy_for_candidate;
                        this.loadVacancy = false;
                  })
                  .catch((error) => {
                      this.$notify({
                          title: "Ошибка ",
                          type: "error",
                          offset: 130,
                          dangerouslyUseHTMLString: true,
                          message: "Вакансия не найдено",
                      });
                  });
          }
      },
      changLang(value){
        // this.fetchQuestions();
        console.log(this.lang_key);
      },
      fetchStatuses() {
          axios
              .get(
                  this.baseUrl +
                      "/vacancy/status/inventory?table_name=candidates"
              )
              .then((res) => {
                  this.statuses = res.data.result.data.statuses;
                  this.form.status_id = _.find(this.statuses, function (o) {
                      return o.code == 1;
                  }).id;
              })
              .catch((error) => {
                  console.log(error);
              });
      },
      fetchQuestions() {
          axios
              .get(
                  this.baseUrl +
                      `/vacancy/questions/${this.$route.params.vacancyUuid}?lang_key=${this.lang_key}`
              )
              .then((res) => {
                  this.questions = res.data.result.data.questions;
               
              })
              .catch((error) => {
                  console.log(error);
              });
      },
      fetchNations() {
          axios
              .get(this.baseUrl + "/vacancy/nation/inventory")
              .then((res) => {
                  this.nations = res.data.result.data.nations;
              })
              .catch((error) => {
                  console.log(error);
              });
      },
      fetchGenders() {
          axios
              .get(this.baseUrl + "/vacancy/gender/inventory")
              .then((res) => {
                  this.genders = res.data.result.data.genders;
              })
              .catch((error) => {
                  console.log(error);
              });
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
      },
      handleFilePreview(file) {
          console.log(file);
      },
      handleFileRemove(file, fileList) {
          console.log(file, fileList);
      },
      beforeFileRemove(file, fileList) {
          // return this.$confirm(`Cancel the transfert of ${ file.name } ?`);
      },
      handleImageRemove(file, fileList) {
          console.log(file, fileList);
      },
      handlePictureCardPreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
      },
      createFileList(file) {
          this.form.resume = file.raw;
      },
      createImageList(file) {
          this.form.image = file.raw;
      },
      submit() {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);       
        // console.log('this.user_options.length',Object.values(this.user_options))
        // console.log('this.questions.length',this.questions.length)
        // console.log('this.user_options',this.user_options.length)
        this.$refs["form"].validate((valid) => {
            if (valid) {
              // console.log('_.without(this.user_options, undefined).length',_.without(this.user_options, undefined).length)
              if ((Object.keys(this.user_options).length==this.questions.length)) {
                  let formData = new FormData();
                  for (var propName in this.form) {
                      if (
                          this.form[propName] === null ||
                          this.form[propName] === undefined ||
                          this.form[propName] == ""
                      ) {
                          delete this.form[propName];
                      }
                  }
                  for (const key in this.form) {
                      formData.append(key, this.form[key]);
                      if (key == "vacancy_ids") {
                          for (const id in this.form[key]) {
                              formData.append(
                                  "vacancy_ids[]",
                                  this.form[key][id]
                              );
                          }
                      }
                  }
                  formData.append(
                      "vacancy_ids[]",
                      this.vacancy_for_candidate.id
                  );
                  formData.append(
                      "user_question_answer_ids",
                      Object.values(this.user_options)
                  );
                  formData.append(
                      "vacancyUuid",
                      this.vacancyUuid
                  );
                  formData.append(
                        "social_id",
                        urlParams.get('social')
                    );
                  let data = {
                      'formData':formData,
                      'vacancyUuid':this.$route.params.vacancyUuid,
                    }
                  this.save(data).then((res) => {
                          this.$alert(res);
                          if (res.status == 201) {
                              this.form = JSON.parse(JSON.stringify({}));
                              this.fileList = [];
                              this.imageList = [];
                              this.user_options={};
                          }
                      })
                      .catch((err) => {
                        console.log(err);
                          this.$alert(err);
                      });
                  // axios
                  //     .post(
                  //         this.baseUrl + "/vacancy/" + this.vacancyUuid,
                  //         formData
                  //     )
                  //     .then((res) => {
                  //         this.$alert(res);
                  //         if (res.status == 201) {
                  //             this.form = JSON.parse(JSON.stringify({}));
                  //             this.fileList = [];
                  //             this.imageList = [];
                  //             this.user_options={};
                  //         }
                  //     })
                  //     .catch((error) => {
                  //       console.log(error);
                  //         this.$alert(error);
                  //     });
              }
              else{
                this.$notify({
                    title: 'Ошибка ',
                    type: "error",
                    offset: 130,
                    message: "Savollarni barchasiga javob berilmagan!"
                  });
              }
            }
          });
      },
  },
};
</script>
<style lang="scss">
.link-mobil {
  display: none;
}
.block_1,
.block_2 {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  position: relative;
}
.logins {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bekmega {
  width: 200px;
}
.gohr {
  width: 150px;
}
.bg-cwe {
  background-color: #dae6e9;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}
.container-my {
  width: 1300px;
  margin: auto;
}
@media (max-width: 1200px) {
  .container-my {
      width: 100%;
      margin: auto;
  }
}

@media (max-width: 500px) {
  .container-my {
      width: 100%;
      margin: auto;
  }
  /* .logins.text-center {
      flex-direction: column;
  }
  .logins.text-center {
      flex-direction: column;
  } */
  .bekmega {
      width: 100px;
  }
  .link-mobil {
      width: 100%;
      background: #00c200;
      display: inline-block;
      color: #fff;
      top: -18px;
      position: absolute;
      right: 0px;
      padding: 10px;
      border-radius: 5px;
      text-align: center;
  }
}
@media (max-width: 320px) {
  .container-my {
      width: 100%;
      margin: auto;
  }
}
.questiuon{
margin: 20px 0 10px 0;
}
.option_item{
border-bottom:1px solid #e5e5e5c6;
padding: 10px 0;
.el-radio{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
}
.question{
margin: 5px 0;
padding: 15px;
border:1px solid #e5e5e5c6;
}
.question_options{
padding: 12px;
border:1px solid #e5e5e5c6;

}
.quiz-item{
margin-bottom: 30px;
}
.question_options .option_item:last-child{
border:none;

}
</style>
